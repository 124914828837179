/**
 * Responsible for defining all the classes and functions for interacting with the backend API.
 * No HTML or styling is defined here.
 */

import { getData, postData, backendUrl, getCookie, REVIEW_LIMIT_TRIAL } from './utils.js';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

export class User {
    static instance = null; // Singleton instance

    static properties = {
        email: { type: String, value: '' },
        is_logged_in: { type: Boolean, value: false },
        subscription_type: { type: String, value: 'no_access' },
        picture: { type: String, value: '' },  // Only available for authors.
        accessed_count: { type: Number, value: 0 },
        is_author: { type: Boolean, value: false },
        sub_end_date: { type: String },
        has_had_trial: { type: Boolean, value: false },
        invitations_left: { type: Number, value: 0 },
    };

    constructor() {
        if (User.instance) {
            return User.instance;
        }
        User.instance = this;
        this.clear();
    }

    static getInstance() {
        return new User();
    }

    clear() {
        this.email = '';
        this.is_logged_in = false;
        this.subscription_type = 'no_access';
        this.picture = '';
        this.accessed_count = 0;
        this.is_author = false;
        this.is_initialized = false;  // To ensure we ping the backend once.
        this.sub_end_date = null;
        this.has_had_trial = false;
        this.invitations_left = 0;
    }

    fromJson(data) {
        this.email = data.email;
        this.accessed_count = data.accessed_count;
        this.subscription_type = data.subscription_type;
        this.is_logged_in = true;
        this.picture = data.picture || '';  // Optional parameter.
        this.is_author = data.is_author;
        this.sub_end_date = data.sub_end_date;
        this.is_initialized = true;
        this.has_had_trial = data.has_had_trial;
        this.invitations_left = data.invitations_left;
    }

    is_subscribed() {
        return this.subscription_type === 'core' || this.subscription_type === 'trial';
    }

    out_of_quota() {
        return this.subscription_type === 'no_access' && this.accessed_count >= REVIEW_LIMIT_TRIAL;
    }

    days_left_on_subscription() {
        if (!this.sub_end_date) return null; // No subscription end date
        const endDate = new Date(this.sub_end_date);
        const today = new Date();
        const timeDiff = endDate - today;
        return Math.ceil(timeDiff / ONE_DAY_MS);
    }

    async ensureInitialized(force = false) {
        if (this.is_initialized && !force) {
            return this;
        }
        const apiUrl = `${backendUrl}/api/v1/user`;
        console.log('Updating user from API:', apiUrl);
        try {
            const data = await getData(apiUrl, getCookie('authToken'));
            if (this.is_initialized) {
                return this;
            }
            this.fromJson(data);
            console.log('User updated from API:', this);
        } catch (error) {
            console.error('Error when fetching user data:', error);
        }
        return this;
    }
}

class Counter {

    static properties = {
        counterName: { type: String },
        entityName: { type: String },
        count: { type: Number, value: 0 },
        has_incremented: { type: Boolean, value: false },
    };

    constructor(counterName, entityName, data = null) {
        this.counterName = counterName;
        this.entityName = entityName;
        if (data && 'count' in data) {
            this.count = data.count;
        }
        if (data && 'by_user' in data) {
            this.has_incremented = data.by_user;
        }
    }

    async handle(){
        if (this.has_incremented) {
            const success = await this.undo_increment()
            console.log('Counter undone:', success);
            return success;
        } else {
            const success = await this.increment()
            console.log('Counter incremented:', success);
            return success;
        }
    }

    async increment() {
        console.log('Incrementing counter:', this.counterName, this.entityName);
        if (this.has_incremented) {
            console.log('Counter cannot be incremented twice');
            return false;
        }

        const apiUrl = `${backendUrl}/api/v1/counter/${this.counterName}/${this.entityName}/increment`;
        console.log('API url:', apiUrl);
        const newCountData = await postData(apiUrl, getCookie('authToken'), null);
        // If 200 is returned, the increment was successful.
        this.count = newCountData.count;
        this.has_incremented = newCountData.by_user;
        return true;
    }

    async undo_increment() {
        if (!this.has_incremented) {
            console.log('Counter has not been incremented, so I cannot undo it.');
            return false;
        }

        const apiUrl = `${backendUrl}/api/v1/counter/${this.counterName}/${this.entityName}/undo`;
        console.log('API url:', apiUrl);
        const newCountData = await postData(apiUrl, getCookie('authToken'), null);
        // If 200 is returned, the increment was successful.
        this.count = newCountData.count;
        this.has_incremented = false;
        return true;
    }

}

export class Author {
    static properties = {
        name: { type: String },
        email: { type: String },
        picture: { type: String },
        bio: { type: String },
        linkedin_url: { type: String },
    };

    constructor(data = null) {
        if (data) {
            this.populateFromJson(data);
        }
    }

    populateFromJson(data) {
        this.name = data.name || '';
        this.email = data.email || '';
        this.picture = data.picture || '';
        this.bio = data.bio || '';
        this.linkedin_url = data.linkedin_url || '';
    }
}

// Only used in add_review.js.
export class ReviewDraftEntity {
    static properties = {
        improvements: { type: Object },
        is_live: { type: Boolean, value: false },
        revision_number: { type: Number, value: 0 },  // Should be called revision count.
        draft_id: { type: Number },
        under_review: { type: Boolean, value: false },
        co_reviewer_email: { type: String },
        reviewer_email: { type: String },
        tags: { type: Array },
        content: { type: String },
        is_user_reviewer: { type: Boolean, value: false },
        draft_ids: { type: Array },
        questions: { type: Array },
        just_auto_assigned: { type: Boolean, value: false },
        paper_html_available: { type: Boolean, value: false },
        // Paper properties:
        paper_id: { type: String },
        paper_abstract: { type: String },
        paper_vote_count: { type: Number, value: 0 },
        paper_publication_date: { type: String },
        paper_image_urls: { type: Array },
        paper_title: { type: String },
        available_authors: { type: Array },
    };

    constructor(paperId, data = null) {
        this.paper_id = paperId;
        if (data) {
            this.populateFromJson(data);
        }
    }

    populateFromJson(data) {
        this.is_live = data.is_live;
        this.improvements = data.improvements;
        this.revision_number = data.revision_number || 0;
        this.draft_id = data.draft_id || null;
        this.under_review = data.under_review;
        this.reviewer_email = data.reviewer_email || '';
        this.co_reviewer_email = data.co_reviewer_email || '';
        this.tags = data.tags || [];
        this.content = data.content || '';
        this.is_user_reviewer = data.is_user_reviewer;
        this.draft_ids = data.draft_ids || [];
        this.paper_abstract = data.paper_abstract || '';
        this.paper_vote_count = data.paper_vote_count || 0;
        this.paper_publication_date = data.paper_publication_date || '';
        this.paper_image_urls = data.paper_image_urls || [];
        this.paper_title = data.paper_title || '';
        this.available_authors = data.available_authors || [];
        this.just_auto_assigned = data.just_auto_assigned;
        this.questions = data.questions || [];
        this.paper_html_available = data.paper_html_available;
    }

    static createApiUrl(paperId, draftId = null) {
        if (draftId) {
            return `${backendUrl}/api/v1/review/${paperId}/draft/${draftId}`;
        } else {
            return `${backendUrl}/api/v1/review/${paperId}/draft`;
        }
    }

    is_latest_draft() {
        if (this.draft_ids.length === 0) {
            return true;
        }
        return this.draft_id === Math.max(...this.draft_ids);
    }

    static async createFromApi(paperId, draftId = null) {
        const token = getCookie('authToken');
        const apiUrl = this.createApiUrl(paperId, draftId);
        try {
            console.log('Fetching review draft data from:', apiUrl);
            const data = await getData(apiUrl, token);
            console.log('Review draft data:', data);
            return new ReviewDraftEntity(paperId, data);
        } catch (error) {
            if (error.message.includes('404')) {
                console.log(`Review not found for paper ID: ${paperId}`);
                return new ReviewDraftEntity();
            } else {
                throw error;
            }
        }
    }

    async getFeedback(content, context = null) {
        const url = `${backendUrl}/api/v1/review/${this.paper_id}/get_feedback`;
        const response = await postData(url, getCookie('authToken'), { "content": content, "context": context });
        return response;
    }

    async updateReview(content, is_live, tags, co_reviewer_email){
        if (!this.under_review) {
            return {error: 'Review not under review, first mark it as under review'};
        }
        try {
            const url = `${backendUrl}/api/v1/review/${this.paper_id}/update`;
            const response = await postData(url, getCookie('authToken'), { "content": content, "is_live": is_live, "tags": tags, "co_reviewer": co_reviewer_email });
            this.is_live = is_live;
            return response;
        } catch (error) {
            return {error: error.message};
        }
    }
}

export class BasicReview {
    static properties = {
        paper_id: { type: String },
        is_reviewed: { type: Boolean, value: false },
        under_review: { type: Boolean, value: false },
        review_date: { type: String },
        is_user_reviewer: { type: Boolean, value: false },
        is_visible_to_user: { type: Boolean, value: true },
        reviewer_email: { type: String },
    }

    constructor(data = null) {
        if (data) {
            this.populateFromJson(data);
        }
    }

    populateFromJson(data) {
        this.paper_id = data.paper_id || '';
        this.under_review = data.under_review;
        this.is_reviewed = data.is_reviewed;
        this.review_date = data.review_date || '';
        this.is_user_reviewer = data.is_user_reviewer;
        this.is_visible_to_user = data.is_visible_to_user;
        this.reviewer_email = data.reviewer_email || '';
        this.readCounter = new Counter('reads', this.paper_id, data.reads);
        this.voteCounter = new Counter('votes', this.paper_id, data.votes);
        this.viewCounter = new Counter('views', this.paper_id, data.views);
        this.bookmarkCounter = new Counter('bookmarks', this.paper_id, data.bookmarks);
    }

    async assignToMe(reviewer_email){
        const apiUrl = `${backendUrl}/api/v1/review/${this.paper_id}/assign_to_me`;
        await getData(apiUrl, getCookie('authToken'));
        console.log('Paper assigned successfully');
        this.reviewer_email = reviewer_email;
        this.under_review = true;
        this.is_reviewed = false;
        this.is_user_reviewer = true;
        this.reviewer_email = 'You';
    }

    async unassign() {
        const apiUrl = `${backendUrl}/api/v1/review/${this.paper_id}/unassign`;
        await getData(apiUrl, getCookie('authToken'));
        console.log('Paper unassigned successfully');
        this.reviewer_email = null;
        this.under_review = false;
        this.is_reviewed = false;
        this.is_user_reviewer = false;
        this.reviewer_email = null;
    }

    filterOnTag(tag) {
        if (tag === 'Unreviewed') {
            return !this.is_reviewed;
        } else if (tag === 'Reviewed') {
            return this.is_reviewed;
        } else if (tag === 'Under review') {
            return this.under_review;
        }
        if (tag === 'Unread') {
            return (!this.readCounter?.has_incremented && this.is_reviewed)
        }
        if (tag === 'Voted by you') {
            return this.voteCounter?.has_incremented;
        }
        if (tag === 'Liked by you') {
            return this.likeCounter?.has_incremented;
        }
        if (tag === 'Bookmarked by you') {
            return this.bookmarkCounter?.has_incremented;
        }
        if (tag === 'Reviewed <14 days') {
            return this.is_reviewed && new Date(this.review_date) > new Date(Date.now() - 14 * ONE_DAY_MS);
        }
        return false;
    }
}

export class FullReview {
    static properties = {
        content: { type: String },
        reviewer_email: { type: String, value: '' },
        paper_id: { type: String },
        author: { type: Author },
        is_visible_to_user: { type: Boolean, value: true },
        visible_in_trial: { type: Boolean, value: true },
        is_user_reviewer: { type: Boolean, value: false },
        voteCounter: { type: Counter },
        likeCounter: { type: Counter },
        readCounter: { type: Counter },
        viewCounter: { type: Counter },
        bookmarkCounter: { type: Counter },
        is_live: { type: Boolean, value: false },
        review_date: { type: String },
    };

    constructor(data = null) {
        if (data) {
            this.populateFromJson(data);
        }
    }

    populateFromJson(data) {
        if ('author' in data) {
            this.author = new Author(data['author']);
            delete data['author'];
        }
        this.under_review = data.under_review;
        this.is_reviewed = data.is_reviewed;
        this.reviewer_email = data.reviewer_email || '';
        this.paper_id = data.paper_id || '';
        this.is_live = data.is_live;
        this.is_user_reviewer = data.is_user_reviewer;
        this.likeCounter = new Counter('likes', this.paper_id, data.likes);
        this.readCounter = new Counter('reads', this.paper_id, data.reads);
        this.voteCounter = new Counter('votes', this.paper_id, data.votes);
        this.viewCounter = new Counter('views', this.paper_id, data.views);
        this.bookmarkCounter = new Counter('bookmarks', this.paper_id, data.bookmarks);
        this.review_date = data.review_date || '';
        this.is_visible_to_user = data.is_visible_to_user;
        this.visible_in_trial = data.visible_in_trial;
        this.revision_number = data.revision_number || 0;
        this.co_reviewer_email = data.co_reviewer_email || '';
        this.tags = data.tags || [];
        this.content = data.content || '';
    }
}

export class PaperDetails {
    static properties = {
        paper_id: { type: String },
        full_review: { type: FullReview },
        date: { type: String },
        cited_count: { type: Number },
        url: { type: String },
        title: { type: String },
        author: { type: String },
        tags: { type: Array },
    };

    constructor(data = null) {
        if (data) {
            this.populateFromJson(data);
        }
    }

    populateFromJson(data) {
        this.paper_id = data.paper_id;
        this.full_review = new FullReview(data.review);
        this.date = data.date;
        this.cited_count = data.cited_count;
        this.url = data.url;
        this.title = data.title;
        this.author = data.author;
        this.tags = data.tags;
    }

    static async createFromApi(paperId) {
        const token = getCookie('authToken');
        const url = `${backendUrl}/api/v1/paper/${paperId}`;
        const papersData = await getData(url, token);
        return new PaperDetails(papersData);
    }
}

export class PaperListEntry {

    static properties = {
        title: { type: String },
        author: { type: String },
        date: { type: String },
        paper_link: { type: String },
        vote: { type: Counter },
        paper_id: { type: String, value: '' },
        cited_count: { type: Number, value: 0 },
        review: { type: BasicReview },
        paper_html_available: { type: Boolean, value: false },
        tags: { type: Array },
    };

    constructor(data = null) {
        if (data) {
            this.populateFromJson(data);
        }
    }

    populateFromJson(data) {
        if ('review' in data) {
            this.review = new BasicReview(data['review']);
        }
        this.title = data.title || '';
        this.author = data.authors[0] || '';
        this.date = data.publication_date || '';
        this.paper_link = data.url || '';
        this.cited_count = data.cited_count || 0;
        this.paper_id = data.paper_id || '';
        this.tags = data.tags || [];
        this.paper_html_available = data.paper_html_available;
    }
}

export async function loadPapersByTag(tag) {
    try {
        const papersData = await getData(`${backendUrl}/api/v1/papers`, getCookie('authToken'));
        return papersData;
    } catch (error) {
        console.error('Error loading papers by tag:', error);
        throw new Error('Failed to load papers. Please try again later.');
    }
}
