import { css } from 'lit';

export const sharedStyles = css`
  body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      font-family: 'Roboto', sans-serif;
  }
  .green-button {
    background-color: rgb(104, 142, 93);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  .green-button:hover {
      background-color: rgb(85, 110, 70);
      transform: scale(1.05);
  }
  .green-button:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(104, 142, 93, 0.5);
  }
  .green-button:disabled {
      background-color: rgba(0, 0, 0, 0.5);
      cursor: not-allowed;
  }
  hr {
    border: none;
    border-bottom: 1px dashed rgba(104, 142, 93, 0.2);
    width: 90%;
    margin: 10px auto 0px;
  }
  .small-grey-text {
    font-size: 0.9rem;
    color: #666;
  }
  .small-button {
    border-radius: 5px;
    background-color: rgb(224, 237, 221);
    border: 1px solid rgba(104, 142, 93, 0.5);
    width: 20px;
    height: 20px;
  }
`;
