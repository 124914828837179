import { LitElement, html, css } from 'lit';
import { setAuthToken, backendUrl, pingBackend, notifyToast } from './utils.js';
import { sharedStyles } from './style.js';
import { User } from './app.js';
import '@shoelace-style/shoelace/dist/components/badge/badge.js';
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

class HeaderElement extends LitElement {

    static properties = {
        user: { type: User },
        is_backend_up: { type: Boolean },
        should_show_login_link: {
            type: Boolean,
            attribute: 'should-show-login-link',
            converter: {
                fromAttribute: (value) => value !== 'false' && value !== null,
                toAttribute: (value) => value ? 'true' : 'false'
            }
        },
    };

    constructor() {
        super();
        this.user = null; // Initialize user to null or a default value
        this.should_show_login_link = true;
        this.is_backend_up = false;
        console.log('Backend URL:', backendUrl);
    }

    async firstUpdated() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            setAuthToken(token);
        }

        this.is_backend_up = await pingBackend();
        if (!this.is_backend_up) {
            console.log('Backend is down. Please try again later.', 'danger');
        }

        this.user = await User.getInstance().ensureInitialized();
        this.requestUpdate();
    }

    static styles = [sharedStyles, css`
        #header {
            display: flex;
            align-items: center;
            padding: 1rem 1.5rem;
            justify-content: space-between;
        }
        #logo_and_name {
            display: flex;
            align-items: center;
        }
        #logo_and_name span {
            font-size: 1.5rem;
            font-weight: bold;
            display: flex;
            align-items: center;
        }
        #logo_and_name a {
            text-decoration: none;
            color: inherit;
        }
        #welcome-message {
            font-size: 0.9rem;
            color: #666;
        }
    `];

    async handleLogout() {
        console.log("Logging out");
        setAuthToken('');
        this.user.clear();
        window.location.href = 'index.html';
    }

    render() {
        return html`
            <div id="header">
                <div id="logo_and_name">
                    <span><a href="library.html">ML Bites</a></span>
                </div>
                <div id="welcome-message">
                    ${this.is_backend_up ? html`` : html`<sl-badge variant="danger">Offline</sl-badge>`}
                    ${this.should_show_login_link ? html`
                        ${(this.user !== null && this.user.is_logged_in) ? html`
                                ${this.user?.email.split('@')[0]}

                                <sl-badge variant="neutral">
                                    ${!this.user?.is_author ? this.user?.subscription_type : 'Author'}
                                </sl-badge>

                                <sl-button  variant="default" pill @click="${this.handleLogout}" size="small">Log out</sl-button>
                        ` : html`
                            <sl-button size="small" pill href="sign_up.html" id="login-link">Login</sl-button>
                            `}
                    ` : ''}
                </div>
            </div>
        `;
    }
}

customElements.define('header-element', HeaderElement);
